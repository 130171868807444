/**
 * @name: 采购管理-供应商管理接口文件
 * @author:
 * @date: 2023-08-04 17:46
 * @description： 采购管理-供应商管理接口文件
 * @update: 2023-08-04 17:46
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import {ISupplier, ISupplierParams} from "@/apis/purchase/supplier/types";
/**
 * 查询供应商列表
 * @param params 查询参数
 * @returns
 */
export const supplierQueryApi = (params: ISupplierParams) => get<IPageRes<ISupplier[]>>("/admin/supplier/query", params)
/**
 * 创建供应商
 * @param data
 * @returns
 */
export const supplierCreateApi = (data: ISupplier) => postJ("/admin/supplier/create", data)
/**
 * 编辑供应商
 * @param data
 * @returns
 */
export const supplierModifyApi = (data: ISupplier) => postJ("/admin/supplier/modify", data)

/**
 * 获取供应商修改状态
 * @param id
 * @returns
 */
export const supplierUpdateStatusApi = (data: ISupplier) => postJ<ISupplier>("/admin/supplier/updateStatus" ,data)
/**
 * 获取供应商删除
 * @param id
 * @returns
 */
export const supplierRemoveApi = (id: string) => get<ISupplier>("/admin/supplier/remove/" + id)
/**
 * 获取供应商详情
 * @param id
 * @returns
 */
export const supplierDetailApi = (id: string) => get<ISupplier>("/admin/supplier/detail/" + id)
