
/**
 * @name: 采购管理-供应商管理
 * @author: lili
 * @date: 2023-08-04 17:53
 * @description： 采购管理-供应商管理
 * @update: 2023-08-04 17:53
 */
import {Component, Vue} from "vue-property-decorator"
import type {ICrudOption} from "@/types/m-ui-crud"
import {
  supplierCreateApi, supplierModifyApi,
  supplierQueryApi,
  supplierRemoveApi,
  supplierUpdateStatusApi
} from "@/apis/purchase/supplier";
import {ISupplier, ISupplierParams} from "@/apis/purchase/supplier/types";
import {checkPhone} from "@/constants/validators";

@Component({})
export default class purchaseSupplier extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: any = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: ISupplierParams = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<ISupplier> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    searchBox: true,
    addTitle: '新增供应商',
    editTitle: '编辑供应商',
    dialogWidth: '800px',
    labelWidth: '180px',
    menu: true,
    editBtn:true,
    delBtn:false,
    column: [
      {
        label: "供应商ID",
        prop: "id",
        align: "center",
        span: 24,
        width:180,
        addSlot: true,
        editSlot: true
      },
      {
        label: "供应商名称",
        prop: "supplierName",
        width:180,
        align: "center",
        search: true,
        span: 12,
        maxlength: 30,
        rules: [
          {required: true, message: "请输入供应商名称", trigger: "blur"}
        ]
      },
      {
        label: "统一信用代码",
        prop: "companyId",
        align: "center",
        span: 12,
        maxlength: 30,
        width:180,
        rules: [
          {required: true, message: "请输入统一信用代码", trigger: "blur"},
          {
            trigger: 'blur', validator: (rule: any, value: any, callback: any) => {
              if (value && !/^[A-Za-z0-9]+$/.test(value)) {
                return callback(new Error('请输入有效的入统一信用代码'))
              }
              callback()
            }
          }

        ]
      },
      {
        label: "联系人",
        prop: "liaison",
        align: "center",
        search: true,
        span: 12,
        maxlength: 20,
        rules: [
          {required: true, message: "请输入联系人", trigger: "blur"},
        ]
      },
      {
        label: "联系电话",
        prop: "phone",
        align: 'center',
        width: 150,
        span: 12,
        maxlength: 11,
        rules: [
          {required: true, message: '请输入联系电话', trigger: 'blur'},
          {trigger: 'blur', validator: checkPhone}
        ],
      },
      {
        label: "电子邮箱",
        prop: "email",
        align: 'center',
        width: 180,
        span: 12,
        rules: [
          {
            required: false, trigger: 'blur', validator: (rule: any, value: any, callback: any) => {
              if (value && !/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)) {
                return callback(new Error('请输入有效的电子邮箱'))
              }
              callback()
            }
          }
        ],
      },
      {
        label: "备注",
        prop: "remarks",
        align: 'center',
        type: 'textarea',
        showWordLimit: true,
        overHidden:true,
        width: 250,
        span: 24,
        maxlength: 200,
      },
      {
        label: "状态",
        prop: "status",
        align: 'center',
        slot: true,
        search: true,
        type: "switch",//状态:1=启用 2=禁用
        value:1,
        dicData: [{label: '禁用', value: 2}, {label: '启用', value: 1}],
        span: 24,

      },
    ]
  }

  async openAdd() {
    this.modelForm.status=1;
    // @ts-ignore
    this.$refs.crudRef.rowAdd()
  }
  /**
   * 新增提交
   */
  submitSave(form: ISupplier, done: any, loading: any) {
    supplierCreateApi(form).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList();
        done();
      }
    }).finally(() => {
      loading();
    })
  }

  /**
   * 编辑提交
   */
  submitEdit(form: ISupplier, done: any, loading: any) {
    supplierModifyApi(form).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList();
        done();
      }
    }).finally(() => {
      loading();
    })
  }
  /**
   * 修改状态提交
   * @param row
   */
  handleStatus(row: ISupplier, val: number) {
    supplierUpdateStatusApi({id:row.id,status:val}).then(e => {
      this.$message({
        type: 'success',
        message: '操作成功!'
      });
      this.getList()
    })
  }

  /**
   * 删除提交
   * @param form
   */
  submitRemove(form: ISupplier, done: any, loading: any) {
    supplierRemoveApi(form.id).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList();
        loading();
        done();
      }
    })
  }

  /**
   * 获取列表数据
   */
  getList() {
    supplierQueryApi(this.queryParam).then(e => {
      //@ts-ignore
      this.tableData = e.list;
      this.tableTotal = e.total
    })
  }

  created() {
    this.getList()
  }
}
